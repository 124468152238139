<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <InfoWidget :title="usersInfoCard.title" :info-items="usersInfoCard.infoItems" />
      </b-col>

      <b-col md="4">
        <InfoWidget :title="feesInfoCard.title" :info-items="feesInfoCard.infoItems" />
      </b-col>

      <b-col md="4">
        <InfoHoldWidget
            title="Hold info"
            :info-items="holdInfo"
        />
      </b-col>

<!--      <b-col md="4">-->
<!--        <InfoWidget :title="transactionsInfoCard.title" :info-items="transactionsInfoCard.infoItems" />-->
<!--      </b-col>-->

      <b-col md="12">
        <ProductsFeesChart />
      </b-col>

      <b-col md="12">
        <ComboChartWithDateRange
          title="Transactions quantity and volume"
          :options="comboCharts.quantityVolume"
          :action="fetchQuantityVolumeTransactions"
          :config="comboLineColumnChart"
          :series-reducer="transactionsQuantityVolumeReducer"
        />
      </b-col>

      <b-col md="12">
        <ComboChartWithDateRange
          title="Transactions volume and fee"
          :options="comboCharts.volumeFee"
          :action="fetchVolumeFeeTransactions"
          :config="comboLineColumnChart"
          :series-reducer="transactionsVolumeFeeReducer"
        />
      </b-col>
      <b-col md="3">
        <InfoWidget :title="todayTransactionsInfoCard.title" :info-items="todayTransactionsInfoCard.infoItems" />
      </b-col>

      <b-col md="3">
        <InfoWidget :title="totalTransactionsInfoCard.title" :info-items="totalTransactionsInfoCard.infoItems" />
      </b-col>

      <b-col md="3">
        <InfoWidget
          :title="todayTransactionsVolumeInfoCard.title"
          :info-items="todayTransactionsVolumeInfoCard.infoItems"
        />
      </b-col>

      <b-col md="3">
        <InfoWidget
          :title="totalTransactionsVolumeInfoCard.title"
          :info-items="totalTransactionsVolumeInfoCard.infoItems"
        />
      </b-col>

      <b-col cols="12">
        <LineChartWithDateRange
          title="Products volume in USD"
          :config="productLineChart"
          :options="lineCharts.products"
          :action="fetchVolumeProducts"
          :series-reducer="productVolumeLineChartReducer"
        />
      </b-col>

      <b-col md="4">
        <ApexBarChart title="Volume (Fee Today)" :options="feeChartToday.options" :series="feeChartToday.series" />
      </b-col>

      <b-col md="4">
        <ApexBarChart title="Volume (Fee Total)" :options="feeChartTotal.options" :series="feeChartTotal.series" />
      </b-col>

      <b-col md="4" />

      <b-col md="4">
        <ApexDonutChart
          v-if='donatPairToday.series.reduce((acc, item) => acc + item, 0) > 0'
          set-id='donatPairTodayChart'
          title="% volume per Pair Today"
          :options="donatPairToday.options"
          :series="donatPairToday.series"
        />
        <ApexDonutChart
          v-else
          set-id='donatPairTodayChart'
          title="% volume per Pair Today"
          :options="donatPairToday.options"
          :series="[0]"
          />
      </b-col>

      <b-col md="4">
        <ApexDonutChart
          v-if="pairsVolumeTotal.length"
          title="% volume per Pair Total"
          :options="donatPairTotal.options"
          :series="donatPairTotal.series"
        />
      </b-col>

      <b-col md="4" />

      <b-col md="4">
        <ApexDonutChart
          v-if="donatDeFiToday.series.length"
          title="DeFi In/Out Today"
          :options="donatDeFiToday.options"
          :series="donatDeFiToday.series"
        />
      </b-col>

      <b-col md="4">
        <ApexDonutChart
          v-if="donatCeFiToday.series.length"
          title="CeFi In/Out Today"
          :options="donatCeFiToday.options"
          :series="donatCeFiToday.series"
        />
      </b-col>

      <b-col md="4">
        <ApexDonutChart
          v-if="donatToday.series.length"
          title="% volume per Product Today"
          :options="donatToday.options"
          :series="donatToday.series"
        />
      </b-col>

      <b-col md="4">
        <ApexDonutChart
          v-if="donatDeFiTotal.series.length"
          title="DeFi In/Out Total"
          :options="donatDeFiTotal.options"
          :series="donatDeFiTotal.series"
        />
      </b-col>

      <b-col md="4">
        <ApexDonutChart
          v-if="donatCeFiTotal.series.length"
          title="CeFi In/Out Total"
          :options="donatCeFiTotal.options"
          :series="donatCeFiTotal.series"
        />
      </b-col>

      <b-col md="4">
        <ApexDonutChart
          v-if="donatTotal.series.length"
          title="% volume per Product Total"
          :options="donatTotal.options"
          :series="donatTotal.series"
        />
      </b-col>

      <!--      <b-col cols="12">-->
      <!--        <LineChartWithDateRange-->
      <!--          title="Users"-->
      <!--          :options="lineCharts.users"-->
      <!--          :action="fetchActiveUsers"-->
      <!--          :series-reducer="usersLineChartReducer"-->
      <!--        />-->
      <!--      </b-col>-->
      <b-col md="12">
        <UsersComboChart />
      </b-col>

      <b-col md="12">
        <DeFiWalletsPieChart />
      </b-col>

      <b-col md="12">
        <ApexFunnelChart title="Total KYC Users" :options="kysChart.options" :series="kysChart.series" />
      </b-col>

      <b-col md="12">
        <UsersMap />
      </b-col>

      <b-col cols="12">
        <b-card>
          <b-card-title class="mb-1">
            Volume map
          </b-card-title>
          <GeoChart :rows="countriesData" />
        </b-card>
      </b-col>

      <b-col cols="12">
        <LineChartWithDateRange
          title="Transactions volume by currency pairs"
          :config="productLineChart"
          :options="pairsVolume"
          :action="pairsVolumeAction"
          :series-reducer="pairsVolumeLineChartReducer"
          series-normalize
        />
      </b-col>

      <b-col cols="12">
        <LineChartWithDateRange
          title="Transactions quantity by currency pairs"
          :config="productLineChart"
          :options="pairsQuantity"
          :action="pairsQuantityAction"
          :series-reducer="pairsQuantityLineChartReducer"
          series-normalize
        />
      </b-col>
    </b-row>

    <b-row>
      <!--      <b-col cols="12">-->
      <!--        <LineChartWithDateRange-->
      <!--          title="Fee volume"-->
      <!--          :config="feeLineChart"-->
      <!--          :options="lineCharts.fee"-->
      <!--          :action="fetchVolumeFee"-->
      <!--          :series-reducer="volumeFeeLineChartReducer"-->
      <!--        />-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

import GeoChart from '@/views/apps/analytics-dashboard/charts/GeoChart.vue'
import ApexDonutChart from '@/views/apps/analytics-dashboard/charts/ApexDonutChart.vue'
import ApexFunnelChart from '@/views/apps/analytics-dashboard/charts/ApexFunnelChart.vue'
import StatisticCardWithAreaChart from '@/views/apps/analytics-dashboard/charts/StatisticCardWithAreaChart.vue'

import funnelChart from '@/views/apps/analytics-dashboard/config/funnelChart'
import donatChart from '@/views/apps/analytics-dashboard/config/donatChart'
import barChart from '@/views/apps/analytics-dashboard/config/barChart'
import LineChart from '@/views/apps/analytics-dashboard/config/lineChart'
import feeLineChart from '@/views/apps/analytics-dashboard/config/feeLineChart'
import productLineChart from '@/views/apps/analytics-dashboard/config/productLineChart'
import comboLineColumnChart from '@/views/apps/analytics-dashboard/config/comboLineColumnChart'
import LineChartWithDateRange from '@/views/apps/analytics-dashboard/charts/LineChartWithDateRange.vue'
import labels from '@/views/apps/analytics-dashboard/config/labels'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import { formattedShortDate, nFormat } from '@/tools/util'
import ApexBarChart from '@/views/apps/analytics-dashboard/charts/ApexBarChart.vue'
import InfoWidget from '@/views/apps/analytics-dashboard/components/InfoWidget.vue'
import InfoHoldWidget from '@/views/apps/analytics-dashboard/components/InfoHoldWidget.vue'
import ComboChartWithDateRange from '@/views/apps/analytics-dashboard/charts/ComboChartWithDateRange.vue'
import ProductsFeesChart from '@/views/apps/analytics-dashboard/components/ProductsFeesChart.vue'
import UsersComboChart from '@/views/apps/analytics-dashboard/components/UsersComboChart.vue'
import DeFiWalletsPieChart from '@/views/apps/analytics-dashboard/components/DeFiWalletsPieChart.vue'
import UsersMap from '@/views/apps/analytics-dashboard/components/UsersMap.vue'

export default {
  name: 'AnalyticsDashboard',
  components: {
    ProductsFeesChart,
    UsersMap,
    DeFiWalletsPieChart,
    UsersComboChart,
    ApexBarChart,
    ComboChartWithDateRange,
    InfoWidget,
    InfoHoldWidget,
    BCardTitle,
    LineChartWithDateRange,
    BCard,
    BRow,
    BCol,
    ApexDonutChart,
    ApexFunnelChart,
    StatisticCardWithAreaChart,
    GeoChart,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      feeLineChart,
      productLineChart,
      labels,
      comboLineColumnChart,
      donatPairTodayChart: null,
    }
  },
  computed: {
    ...mapGetters({
      total: 'dashboard/total',
      map: 'dashboard/map',
      volumeFeeSnapshot: 'dashboard/volumeFeeSnapshot',
      lineCharts: 'dashboard/lineCharts',
      countries: 'countries/countries',
      comboCharts: 'dashboard/comboCharts',
      statsToday: 'dashboard/statsToday',
      statsTotal: 'dashboard/statsTotal',
      volumeQuantityToday: 'dashboard/volumeQuantityToday',
      volumeQuantityTotal: 'dashboard/volumeQuantityTotal',
      pairsList: 'dashboard/pairsList',
      pairsVolumeToday: 'dashboard/pairsVolumeToday',
      pairsVolumeTotal: 'dashboard/pairsVolumeTotal',
      pairsVolume: 'dashboard/pairsVolume',
      pairsQuantity: 'dashboard/pairsQuantity',
      pairsListLabels: 'dashboard/pairsListLabels',
      statsFees: 'dashboard/statsFees',
      holdInfo: 'dashboard/holdInfo',
    }),

    accountsAmount() {
      return this.nFormat(this.total?.totalAccounts || 0)
    },
    walletsAmount() {
      return this.nFormat(this.total?.totalWallets || 0)
    },

    todayTransactionsAmount() {
      return this.nFormat(this.total?.transactionsNumberToday || 0)
    },

    totalTransactionsAmount() {
      return this.nFormat(this.total?.transactionsNumberTotal || 0)
    },

    usersInfoCard() {
      return {
        title: 'Total Users',
        infoItems: [
          {
            icon: 'UsersIcon',
            color: 'primary',
            title: 'Fintopio accounts',
            statistic: this.accountsAmount,
          },
          {
            icon: 'CreditCardIcon',
            color: 'warning',
            title: 'Fintopio wallets',
            statistic: this.walletsAmount,
          },
        ],
      }
    },

    transactionsInfoCard() {
      return {
        title: 'Transactions',
        infoItems: [
          {
            icon: 'ShuffleIcon',
            color: 'primary',
            title: 'Today Transactions',
            statistic: this.todayTransactionsAmount,
          },
          {
            icon: 'RefreshCcwIcon',
            color: 'warning',
            title: 'Total Transactions',
            statistic: this.totalTransactionsAmount,
          },
        ],
      }
    },

    feesInfoCard() {
      return {
        title: 'Fees',
        infoItems: [
          {
            icon: 'TrendingDownIcon',
            color: 'primary',
            title: 'Today Fee',
            statistic: this.nFormat(this.statsFees?.today || 0),
          },
          {
            icon: 'TrendingUpIcon',
            color: 'warning',
            title: 'Total Fee',
            statistic: this.nFormat(this.statsFees?.total || 0),
          },
        ],
      }
    },

    todayTransactionsInfoCard() {
      return {
        title: 'Transactions Today',
        infoItems: [
          {
            icon: 'ArrowDownRightIcon',
            title: 'Min swap amount today',
            statistic: this.nFormat(this.statsToday?.min || 0),
          },
          {
            icon: 'ArrowUpRightIcon',
            color: 'warning',
            title: 'Max swap amount today',
            statistic: this.nFormat(this.statsToday?.max || 0),
          },
          {
            icon: 'ArrowRightIcon',
            color: 'info',
            title: 'Avg swap amount today',
            statistic: this.nFormat(this.statsToday?.avg || 0),
          },
        ],
      }
    },

    totalTransactionsInfoCard() {
      return {
        title: 'Transactions Total',
        infoItems: [
          {
            icon: 'ArrowDownRightIcon',
            title: 'Min swap amount total',
            statistic: this.nFormat(this.statsTotal?.min || 0),
          },
          {
            icon: 'ArrowUpRightIcon',
            color: 'warning',
            title: 'Max swap amount total',
            statistic: this.nFormat(this.statsTotal?.max || 0),
          },
          {
            icon: 'ArrowRightIcon',
            color: 'info',
            title: 'Avg swap amount total',
            statistic: this.nFormat(this.statsTotal?.avg || 0),
          },
        ],
      }
    },

    todayTransactionsVolumeInfoCard() {
      return {
        title: 'Transactions Volume/Quantity Today',
        infoItems: [
          {
            icon: 'ArrowDownRightIcon',
            title: 'Volume today',
            statistic: this.nFormat(this.volumeQuantityToday?.volume || 0),
          },
          {
            icon: 'ArrowUpRightIcon',
            color: 'warning',
            title: 'Quantity today',
            statistic: this.nFormat(this.volumeQuantityToday?.quantity || 0),
          },
        ],
      }
    },

    totalTransactionsVolumeInfoCard() {
      return {
        title: 'Transactions Volume/Quantity Total',
        infoItems: [
          {
            icon: 'ArrowDownRightIcon',
            title: 'Volume total',
            statistic: this.nFormat(this.volumeQuantityTotal?.volume || 0),
          },
          {
            icon: 'ArrowUpRightIcon',
            color: 'warning',
            title: 'Quantity total',
            statistic: this.nFormat(this.volumeQuantityTotal?.quantity || 0),
          },
        ],
      }
    },

    kysChart() {
      return this.toLineBarChart(this.total?.totalUsersByKYC || [], this.kysChartReducer, funnelChart)
    },

    feeChartTotal() {
      return this.toBarChart(this.volumeFeeSnapshot?.volumesWithFeeTotal || [])
    },
    feeChartToday() {
      return this.toBarChart(this.volumeFeeSnapshot?.volumesWithFeeToday || [])
    },

    donatCeFiToday() {
      const products = {
        cefiIn: this.total?.volumesByProductToday?.products?.cefiIn || 0,
        cefiOut: this.total?.volumesByProductToday?.products?.cefiOut || 0,
      }
      return this.toDonatChart(this.total?.volumesByProductToday?.products ? products : {})
    },

    donatCeFiTotal() {
      const products = {
        cefiIn: this.total?.volumesByProductTotal?.products?.cefiIn || 0,
        cefiOut: this.total?.volumesByProductTotal?.products?.cefiOut || 0,
      }
      return this.toDonatChart(this.total?.volumesByProductToday?.products ? products : {})
    },

    donatDeFiToday() {
      const products = {
        defiIn: this.total?.volumesByProductToday?.products?.defiIn || 0,
        defiOut: this.total?.volumesByProductToday?.products?.defiOut || 0,
      }
      return this.toDonatChart(this.total?.volumesByProductToday?.products ? products : {})
    },

    donatDeFiTotal() {
      const products = {
        defiIn: this.total?.volumesByProductTotal?.products?.defiIn || 0,
        defiOut: this.total?.volumesByProductTotal?.products?.defiOut || 0,
      }
      return this.toDonatChart(this.total?.volumesByProductToday?.products ? products : {})
    },

    donatToday() {
      return this.toDonatChart(this.total?.volumesByProductToday?.products || {})
    },

    donatTotal() {
      return this.toDonatChart(this.total?.volumesByProductTotal?.products || {})
    },

    donatPairToday() {
      return this.toPairDonatChartToday(this.pairsVolumeToday || {})
    },

    donatPairTotal() {
      return this.toPairDonatChart(this.pairsVolumeTotal || {})
    },
    transactionsVolumeFeeComboLineColumnChart() {
      return this.toComboLineColumnChart(
        this.total?.transactionsQuantityVolume || [],
        this.transactionsVolumeFeeReducer,
      )
    },

    countriesData() {
      const countries = Object.entries(this.map || {})

      return (
        countries?.map(([id, amount]) => {
          const { name } = this.findCountries(id)

          return [name, amount]
        }) || []
      )
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    nFormat,
    formattedShortDate,
    ...mapActions({
      fetchTotal: 'dashboard/fetchTotal',
      fetchMap: 'dashboard/fetchMap',
      fetchVolumeFeeSnapshot: 'dashboard/fetchVolumeFeeSnapshot',
      fetchVolumeFee: 'dashboard/fetchVolumeFee',
      fetchVolumeProducts: 'dashboard/fetchVolumeProducts',
      fetchCountries: 'countries/fetchCountries',
      fetchQuantityVolumeTransactions: 'dashboard/fetchQuantityVolumeTransactions',
      fetchVolumeFeeTransactions: 'dashboard/fetchVolumeFeeTransactions',
      fetchStatsToday: 'dashboard/fetchStatsToday',
      fetchStatsTotal: 'dashboard/fetchStatsTotal',
      fetchVolumeQuantityToday: 'dashboard/fetchVolumeQuantityToday',
      fetchVolumeQuantityTotal: 'dashboard/fetchVolumeQuantityTotal',
      fetchPairsList: 'dashboard/fetchPairsList',
      fetchPairsVolumeToday: 'dashboard/fetchPairsVolumeToday',
      fetchPairsVolumeTotal: 'dashboard/fetchPairsVolumeTotal',
      fetchPairsVolume: 'dashboard/fetchPairsVolume',
      fetchPairsQuantity: 'dashboard/fetchPairsQuantity',
      fetchStatsFees: 'dashboard/fetchStatsFees',
      fetchHoldInfo: 'dashboard/fetchHoldInfo',
    }),

    initState() {
      this.fetchTotal().catch(this.checkErrors)
      this.fetchMap().catch(this.checkErrors)
      this.fetchCountries().catch(this.checkErrors)
      this.fetchVolumeFeeSnapshot().catch(this.checkErrors)
      this.fetchStatsToday().catch(this.checkErrors)
      this.fetchStatsTotal().catch(this.checkErrors)
      this.fetchPairsVolumeToday().catch(this.checkErrors)
      this.fetchPairsVolumeTotal().catch(this.checkErrors)
      this.fetchVolumeQuantityToday().catch(this.checkErrors)
      this.fetchVolumeQuantityTotal().catch(this.checkErrors)
      this.fetchHoldInfo().catch(this.checkErrors)
      this.fetchStatsFees().catch(this.checkErrors)
    },

    findCountries(id) {
      return this.countries?.find(country => country.id === parseInt(id, 10))
    },

    toDonatChart(volume) {
      const products = Object.entries(volume)

      const { labels, series } = products?.reduce(
        (acc, [key, amount]) => ({ series: [...acc.series, amount], labels: [...acc.labels, this.labels[key] || ''] }),
        { series: [], labels: [] },
      )

      const options = {
        ...donatChart.chartOptions,
        labels,
      }

      return {
        options,
        series,
      }
    },

    toPairDonatChart(volume) {
      const products = Object.entries(volume)

      let { labels, series } = products?.reduce(
        (acc, [key, amount]) => {
          return {
            series: [...acc.series, amount.percentage],
            labels: [...acc.labels, `${amount.from.symbol}-${amount.to.symbol}`],
          }
        },
        { series: [], labels: [] },
      )

      if (!labels.length) {
        labels = []
        series = []
      }

      const options = {
        ...donatChart.chartOptions,
        chart: {
          height: 450,
        },
        labels,
        legend: {
          ...donatChart.chartOptions.legend,
          height: 100
        }
      }

      return {
        options,
        series,
      }
    },

    toPairDonatChartToday(volume) {
      const products = Object.entries(volume)

      let { labels, series } = products?.reduce(
        (acc, [key, amount]) => {
          return {
            series: [...acc.series, amount.percentage],
            labels: [...acc.labels, `${amount.from.symbol}-${amount.to.symbol}`],
          }
        },
        { series: [], labels: [] },
      )

      let options = {
            ...donatChart.chartOptions,
            chart: {
              height: 450,
            },
            labels,
            legend: {
              ...donatChart.chartOptions.legend,
              height: 100
            },
            plotOptions: {
              ...donatChart.chartOptions.plotOptions,
              pie: {
                ...donatChart.chartOptions.plotOptions.pie,
                donut: {
                  labels: {
                    ...donatChart.chartOptions.plotOptions.pie.donut.labels,
                    total: {
                      ...donatChart.chartOptions.plotOptions.pie.donut.labels.total,
                      formatter(val) {
                        return nFormat(val.config?.series?.reduce((acc, item) => acc + item, 0) || 100)
                      },
                    },
                  },
                },
              },
            },
          }

      if (!labels.length) {
        labels = ['No data']
        series = [0]
        options = {
          ...donatChart.chartOptions,
          chart: {
            height: 450,
          },
          labels,
          legend: {
            ...donatChart.chartOptions.legend,
            height: 100
          }
        }
      }
      console.log('pairsVolumeToday', volume)
      return {
        options,
        series,
      }
    },

    toBarChart(fee) {
      if (
        !fee ||
        (Array.isArray(fee) && !fee.length) ||
        (Object.keys(fee).length === 0 && fee.constructor === Object)
      ) {
        return {
          options: {
            ...barChart.chartOptions,
          },
          series: [],
        }
      }
      const { totalVolume, totalFee, dealsTotalVolume, dealsTotalFee, buySellTotalVolume, buySellTotalFee } = fee || {}

      const feeText = `Total Fee ${this.nFormat(totalFee || 0)}$`
      const volumeText = `Total Volume ${this.nFormat(totalVolume || 0)}$`

      const options = {
        ...barChart.chartOptions,
        xaxis: { categories: [feeText, volumeText] },
      }

      const seriesOptions = [
        // { name: 'Total', data: [totalFee, totalVolume] },
        { name: 'Deals', data: [dealsTotalFee, dealsTotalVolume] },
        { name: this.labels.exchange, data: [buySellTotalFee, buySellTotalVolume] },
      ]

      return {
        options,
        series: seriesOptions,
      }
    },

    toLineBarChart(range, reducer, config = LineChart) {
      const { categories = [], series = [] } = range?.reduce(reducer, { series: [], categories: [] })

      const options = {
        ...config.chartOptions,
        xaxis: { categories },
      }

      const seriesOptions = [{ name: '', data: series }]

      return {
        options,
        series: seriesOptions,
      }
    },

    toComboLineColumnChart(range, reducer) {
      const { categories = [], series = [] } = range?.reduce(reducer, { series: [], categories: [] })

      const options = {
        ...this.comboLineColumnChart.chartOptions,
        xaxis: { categories },
      }

      return {
        options,
        series,
      }
    },

    transactionsQuantityVolumeReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.date)]
      const series = [
        { name: 'Quantity', data: [...(acc.series?.[0]?.data || []), item.quantity], tooltip: '' },
        {
          name: 'Volume',
          data: [...(acc.series?.[1]?.data || []), item.volume],
          type: 'column',
          tooltip: 'USDT',
        },
      ]

      return { series, categories }
    },

    transactionsVolumeFeeReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.date)]
      const series = [
        {
          name: 'Fee',
          data: [...(acc.series?.[0]?.data || []), item.fee],
        },
        {
          name: 'Volume',
          data: [...(acc.series?.[1]?.data || []), item.volume],
          type: 'column',
          tooltip: 'USDT',
        },
      ]

      return { series, categories }
    },

    usersLineChartReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.checkedDate)]
      const series = [{ name: '', data: [...(acc.series?.[0]?.data || []), item.amount] }]

      return { series, categories }
    },

    volumeFeeLineChartReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.checkedDate)]
      const series = [
        { name: 'Total Fee', data: [...(acc.series?.[0]?.data || []), item.data?.fee] },
        { name: 'Total Volume', data: [...(acc.series?.[1]?.data || []), item.data?.volume] },
      ]

      return { series, categories }
    },

    productVolumeLineChartReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.checkedDate)]

      const series = [
        { name: this.labels.swap, data: [...(acc.series?.[0]?.data || []), item.data?.swap] },
        { name: this.labels.cefiIn, data: [...(acc.series?.[1]?.data || []), item.data?.cefiIn] },
        { name: this.labels.defiIn, data: [...(acc.series?.[2]?.data || []), item.data?.defiIn] },
        { name: this.labels.cefiOut, data: [...(acc.series?.[3]?.data || []), item.data?.cefiOut] },
        { name: this.labels.defiOut, data: [...(acc.series?.[4]?.data || []), item.data?.defiOut] },
        { name: this.labels.exchange, data: [...(acc.series?.[5]?.data || []), item.data?.exchange] },
        { name: this.labels.p2p, data: [...(acc.series?.[6]?.data || []), item.data?.p2p] },
      ]

      return { series, categories }
    },
    pairsVolumeLineChartReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.date)]

      const startArr = this.pairsListLabels?.map(label => ({ data: [], name: label }))

      const series = startArr.map((pair, index) => {
        const volume = item.pairs.find(itemPair => itemPair.name === pair.name)?.volume || 0
        return { name: pair.name, data: [...(acc.series?.[index]?.data || []), volume] }
      })

      return { series, categories }
    },

    pairsQuantityLineChartReducer(acc, item) {
      const categories = [...acc.categories, this.formattedShortDate(item.date)]

      const startArr = this.pairsListLabels?.map(label => ({ data: [], name: label }))

      const series = startArr.map((pair, index) => {
        const quantity = item.pairs.find(itemPair => itemPair.name === pair.name)?.quantity || 0
        return { name: pair.name, data: [...(acc.series?.[index]?.data || []), quantity] }
      })

      return { series, categories }
    },

    kysChartReducer(acc, item) {
      const categories = [...acc.categories, item.level]
      const series = [...acc.series, item.count]

      return { series, categories }
    },

    pairsVolumeAction(query) {
      return this.fetchPairsList()
        .catch(this.checkErrors)
        .then(() => {
          this.fetchPairsVolume(query).catch(this.checkErrors)
        })
    },
    pairsQuantityAction(query) {
      return this.fetchPairsList()
        .catch(this.checkErrors)
        .then(() => {
          this.fetchPairsQuantity(query).catch(this.checkErrors)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/vue/libs/chart-apex.scss';
</style>
