<template>
  <b-card>
    <b-card-title
      v-if="title"
      class="mb-1"
    >
      {{ title }}
    </b-card-title>
    <b-card-sub-title
      v-if="subTitle"
      class="mb-2"
    >
      {{ subTitle }}
    </b-card-sub-title>

    <vue-apex-charts
      :id="setId"
      ref="apexChart"
      type="donut"
      :height="options.chart.height"
      :options="options"
      :series="series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { nFormat } from '@/tools/util'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    setId: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.$nextTick(
      () => {
        setTimeout(() => {
          if (this.setId) {
            this.$refs.apexChart.updateSeries(this.series)
          }
        }, 2000)
      },
    )
  },
}
</script>
